import React, { useState, useEffect } from 'react';
import * as qf from '../../misc/qf';
import ErrorBoundary from '../../misc/ErrorBoundary.js'
import TweetPostEmbed from './userTweets/TweetPostEmbed.jsx';
import PostTweet from './userTweets/PostTweet.jsx';

import './LoadTweets.css';

let isScriptLoaded = false;
let count = 0;

const LoadTweets = ({ twitterInfo, href, postUrl, canPost }) => {
  const [tweetInfo, setTweetInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastPostDate, setLastPostDate] =  useState(null);
  const [nextPageUrl, setNextPageUrl] = useState('');
  const [isLastPost, setIsLastPost] = useState(false);
  const [postMetaArr, setPostMetaArr] = useState({});
  let localState = {
    isLoading: false,
    isLastPost: false,
  }

  useEffect( () => {
    const el = document.getElementById("twitter");
    const config = { attributes: true };
    const feedLoadFunc = () => {
      qf.getJSON(href)
        .then( res => {
          const tweets = res.tweets
          setTweetInfo(tweets);
          setLastPostDate(tweets[tweets.length - 1].post_time);
          if(res.last_posts) setIsLastPost(res.last_posts);
        })
        .catch( err => console.error(err) );

      // Load Twitter Embed Script
      if(isScriptLoaded) return;
      const script = document.createElement('script');
      script.src = "https://platform.twitter.com/widgets.js";
      script.setAttribute("async", "");
      script.setAttribute("charset", "utf-8");
  
      let body = document.querySelector('body');
      body.appendChild(script);
      isScriptLoaded = true;
      script.onLoad = () => window.twttr.widgets.load();
  
      setupScrollWatch();
    }
    if (el.classList.contains('active')) {
      feedLoadFunc();
    } else {
      const cb = (mutationList, observer) => {
        for (let mutation of mutationList) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'class' && el.classList.contains('active')) {
            feedLoadFunc();
          }
        }
      }
      const observer = new MutationObserver(cb);
      observer.observe(el, config);

      () => observer.disconnect();
    }
  },[]);

  useEffect( () => {
    const loadingSpinnerEl = document.getElementById('loadingSpinnerTwitter');
    const loadPostsObserver = new IntersectionObserver( (entries, observer) => entries.forEach(entry => entry.isIntersecting && getNextPage(localState)) );
    if(isLastPost) {
      loadPostsObserver.unobserve(loadingSpinnerEl);
      loadingSpinnerEl.classList.add("u-hide");
    } else {
      loadPostsObserver.observe(loadingSpinnerEl)
      return() => loadPostsObserver.unobserve(loadingSpinnerEl);
    }
  }, [nextPageUrl, isLastPost]);

  useEffect( () => {
    let url;
    if(lastPostDate !== null) {
      url = `${href}&date=${lastPostDate}`;
    } else {
      url = `${href}`;
    }
    setNextPageUrl(url);
  }, [lastPostDate]);

  useEffect( () => {
    localState.isLastPost = isLastPost;
  }, [isLastPost]);

  useEffect( () => {
    loadTwitterEmbeds();
  }, [tweetInfo])

  function getNextPage(localState) {
    if(localState.isLoading) return;
    localState.isLoading = true;
    setIsLoading(true);
    qf.getJSON(nextPageUrl)
      .then( res => {
        let tweets = res.tweets;

        if(tweets.length !== 0) {
          setLastPostDate(tweets[tweets.length - 1].post_time);
          setTweetInfo([ ...tweetInfo, ...tweets ]);
        }

        if(res.last_posts) setIsLastPost(res.last_posts);
        setIsLoading(false);
      })
      .catch( err => console.error(err) )
      .finally( () => {
        setIsLoading(false);
        localState.isLoading = false;
      });
  };

  const loadTwitterEmbeds = () => {
    const el = document.querySelector('#twitterContainer .results');
    if(window.twttr) window.twttr.widgets.load(el);
  };

  const scrollDetectPosts = () => {
    const postsOnPage = document.querySelectorAll('#twitterContainer .twitter-tweet');

    let showhide = {...postMetaArr};
    postsOnPage.forEach( (el, idx) => {
      const firstChild = el.firstChild;

      if(firstChild.tagName === "IFRAME") {
        const childHeight = firstChild.clientHeight;
        const childOffsetTop = firstChild.offsetTop;
        const scrollHeight = window.scrollY;

        const distanceToHide = 4000; // distance from viewable area to hide post
        const elHeight = childOffsetTop + childHeight; // bottom position of post element
        const liveHeight = window.innerHeight + scrollHeight + distanceToHide; // lower bound to hide posts
        const turnOffTop = scrollHeight - distanceToHide; // upper bound to hide posts
        showhide = {...showhide, [idx]: ((elHeight - turnOffTop) < 0) || (childOffsetTop - liveHeight > 0) };
      }
    });
    setPostMetaArr(showhide);
  };

  // debounce scroll to prevent multiple firings while scrolling
  const debounce = (func, delay) => {
    clearTimeout(func._timeout);
    func._timeout = setTimeout( () => func(), delay);
  }

  const setupScrollWatch = () => {
    window.addEventListener('scroll', () => debounce(scrollDetectPosts, 500));
  };

  return (
    <div className="results">
      { canPost &&(
        <PostTweet postUrl={postUrl} setTweetInfo={setTweetInfo} tweetInfo={tweetInfo} />
      )}
      <div id="TweetFeedContainer">
        {
          (tweetInfo && tweetInfo.length !== 0) && tweetInfo.map( (tweet, idx) => <ErrorBoundary key={`userTweetComponent-${tweet.id}`}><TweetPostEmbed tweet={tweet} tweetInfo={{ href: href, tweetInfo: tweetInfo, setTweetInfo: setTweetInfo, isLoading: isLoading, setIsLoading: setIsLoading }} showHide={postMetaArr[idx]} idx={idx} user={twitterInfo} /></ErrorBoundary> )
        }
      </div>
      <div id="loadingSpinnerTwitter" className="w-100 text-center py-5">
        {
          isLoading ? (
            <>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading Posts&hellip;
            </>
          ) : <></>
        }
      </div>
    </div>
  )
}

export default LoadTweets;