import ReactDOM from 'react-dom';
import preloaded from '../../src/preloaded';

import LoadTweets from '../../src/socialMedia/twitter/LoadTweets.jsx';

const { team_twitter, twitter_info, twitter_url, twitter_post_url, can_post_social } = preloaded;

const el = document.getElementById('twitterContainer');

if(el && !!team_twitter) ReactDOM.render( <LoadTweets twitterInfo={twitter_info} href={twitter_url} postUrl={twitter_post_url} canPost={can_post_social} />, el);
