import React, { useState, useEffect, useRef } from 'react';

import './TweetPostEmbed.css';

const TweetPostEmbed = ({ tweet, user, showHide, idx, twitter_info, ...rest }) => {
  const [twitterUrl, setTwitterUrl] = useState('');
  const [entities, setEntities] = useState(null);
  const [isRetweet, setIsRetweet]= useState(false);
  const elRef = useRef(null);
  let used = [];

  useEffect( () => {
    if(tweet.entities) {
      const ents = JSON.parse(tweet.entities);
      if(Object.keys(ents).length > 0) setEntities(ents);
    }
    const textFirstTwoChar = tweet.text.slice(0, 4);
    if(textFirstTwoChar === "RT @") setIsRetweet(true);
  }, []);

  useEffect( () => {
    const el = document.querySelector(`#tweet-${tweet.conversation_id} .twitter-tweet > iframe`);

    if( el == null) return;

    if(showHide) {
      setTwitterUrl(el.getAttribute('src'));
      el.setAttribute('src', '');
    } else if (twitterUrl !== '') {
      el.setAttribute('src', twitterUrl);
    }
  }, [showHide]);

  const postDate = new Date(tweet.post_time);
  const dateOptions = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  };

  const blockQuote = (
    <blockquote className="twitter-tweet shadow-sm">
      <p lang="zxx" dir="ltr">
        {
          entities && entities.urls ? (
            entities.urls.map( (item, idx) => {
              if(!used.includes(item.url)) {
                used.push(item.url);
                return <React.Fragment key={tweet.conversation_id ? tweet.conversation_id + `-${idx}` : `tc-${idx}`}><a href={item.url}>{item.url}</a></React.Fragment>
              } else {
                return <React.Fragment key={tweet.conversation_id ? tweet.conversation_id + `-${idx}` : `tc-${idx}`}></React.Fragment>
              }
            })
            ) : (
              <></>
              )
            }
      </p>
      <p>
        &mdash; {user.name} (@{user.username}) <a href={`https://twitter.com/${user.username}/status/${ tweet.conversation_id ? tweet.conversation_id : '' }`}>{postDate.toLocaleString("en-US", dateOptions)}</a>
      </p>
    </blockquote>
  );

  return (
    <div id={`tweet-${tweet.conversation_id}`} className="twitterPost" ref={elRef}>
      { isRetweet ? (
        <div className="Retweet">
          <h6 className="RetweetTitle"><i className="fa-solid fa-retweet"></i> <a href={`//twitter.com/${user.username}`} target="_blank">{user.name}</a> reposted</h6>
          {blockQuote}
        </div>
      ) : (
        <>
          {blockQuote}
        </>
      )}
    </div>
  );
};

export default TweetPostEmbed;
