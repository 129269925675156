import React, { useState } from 'react';
// import * as qf from '../../../misc/qf';
import csrfToken from '../../../csrfToken';

import './PostTweet.css';

const PostTweet = ({ postUrl, setTweetInfo, tweetInfo }) => {
  const [showForm, setShowForm] = useState(false);
  const postToTwitter = e => {
    setShowForm(!showForm);
  }

  return (
    <div className="PostTweetContainer">
      {
        showForm && <TweetForm postUrl={postUrl} setShowForm={setShowForm} setTweetInfo={setTweetInfo} tweetInfo={tweetInfo} />
      }
      <div id="PostTweet--btn" className="btn btn-primary" onClick={postToTwitter}>
        <i className="fa-solid fa-plus"></i><span className="BtnText">&nbsp;&nbsp;Post a Tweet</span>
      </div>
    </div>
  )
};

const TweetForm = ({ postUrl, setShowForm, setTweetInfo, tweetInfo }) => {
  const [tweet, setTweet] = useState('');
  const [postTweet, setPostTweet] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [error, setError] = useState(undefined);
  const [submitting, setSubmitting] = useState(false);

  const onChangeHandler = e => {
    const value = e.target.value;
    if(value.length <= 280) {
      setTweet(value);
      setCharCount(value.length);
      setError(undefined);
    }
  };

  const submit = e => {
    e.preventDefault();
    setSubmitting(true);
    const token = csrfToken();
    if(tweet.trim() == "") {
      setShowForm(false);
      return;
    }
    fetch(postUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token,
        'X-Requested-With': 'XMLHttpRequest',
      },
      credentials: 'same-origin',
      body: JSON.stringify({ text : tweet }),
    })
    .then( async res => {
      if (res.status >= 200 && res.status < 300) {
        const response = await res.json();
        const postedTweetInfo = response.msg;
        let dateNow = new Date

        setPostTweet({ tweets: response.data, includes: response.includes });
        setSubmitting(false);
        setTimeout( () => {
          setPostTweet('');
          let tweet = {
            id: parseInt(postedTweetInfo.id.slice(-3)),
            conversation_id: postedTweetInfo.id,
            post_time: dateNow.toISOString(),
            text: postedTweetInfo.text,
          }
          const newTweets = [ tweet, ...tweetInfo ];

          setTweetInfo(newTweets);
          setShowForm(false);
        }, 2000);
      } else {
        var error = new Error(res.statusText);
        error.response = res;
        throw error;
      }
    })
    .catch(async  err => {
      const response = await err.response.json();

      if(err.response.status === 429) {
        setError({ status: err.response.status, statusText: err.response.statustext, info: 'You have submitted too many posts.' })
      } else if (err.response.status >= 400 && err.response.status < 500) {
        setError({ status: err.response.status, statusText: err.response.statusText, info: response.msg })
      };
      setSubmitting(false);

      setTimeout( () => {
        setPostTweet('');
        setError(undefined);
        setShowForm(false);
      }, 2000);
    });
  }

  return (
    <div className="PostWrapper">
      <form onSubmit={submit}>
        <div className="close" onClick={ () => setShowForm(false) }><i className="fa-sharp fa-solid fa-xmark"></i></div>
        <textarea value={tweet} onChange={onChangeHandler} id='tweet' name="text" max-length="280" placeholder="Type tweet here"></textarea><br />
        <div className="FormInfo">
          <small>{280 - charCount}/280</small>
          <button type="submit" className={`btn ${ submitting ? 'btn-secondary' : postTweet === '' ? 'btn-primary' : 'btn-success'}`}>
            {
              submitting ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span className="visually-hidden">Submitting&hellip;</span>
                </>
                ) : postTweet === '' ? (
                  'Submit Post'
                ) : (
                  'Post Sent'
                )
              }
          </button>
        </div>
        {
          error !== undefined && (
            <div className="my-3 py-3 px-4 bg-danger text-white text-center u-borderRadius">
              <strong>{error.status && error.status}{error.statusText && ": " + error.statusText}</strong><br />{error.info && error.info}
            </div>
          )
        }
      </form>
    </div>
  )
}

export default PostTweet;